import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import PerfectScrollbar from "react-perfect-scrollbar";
import { useHistory } from "react-router-dom";
// import "react-perfect-scrollbar/dist/css/styles.css";
import { getProductosCar } from "../../services/car";
import { formatMonto } from "../utils";
import ButtonMinus from "./buttonMinus";
import ButtonPlus from "./buttonPlus";
import ButtonTrash from "./ButtonTrash";
const DetailCar = ({setMontoTienda, setPreloader}) => {
    const carStore = useSelector((state) => state.car);
    const global = useSelector((state) => state.global);
    const moneda = useSelector((state) => state.event.selectedFunction.Iso);
    const MonedaTienda = useSelector((state) => state.event.selectedFunction.Iso === 'VES' ? state.global.MonedaPorDefecto : state.event.selectedFunction.Iso);

    // const shop = useSelector((state) => state.shop);
    const [SubTotal, setSubTotal] = useState(0);
    const [CantidadProds, setCantidadProds] = useState(0);
    const [Descuento] = useState(0);
    const [Productos, setProductos] = useState([]);
    const dispatch = useDispatch();

    const navigate = useHistory();

    useEffect(() => {
        (async () => {
            if(setPreloader){
                setPreloader(true);
            }
            if (carStore.productos.length >= 0) {
                let pArray = [];
                let resp = await getProductosCar(carStore.productos, MonedaTienda);
                
                console.log('resp',resp)
                resp.map((r) => {
                    if (r.status === 200) {
                        pArray.push(r.data);
                    }
                });
                
                setProductos(pArray || []);
                
                if (pArray !== null) {
                    let acum = 0;
                    let cant = 0;
                    carStore.productos.map((p) => {
                        let producto = pArray.find((n) => n.id === p.id);
                        let _tV = getTotalVariantes(producto, p);
                        acum =
                            acum +
                            (producto?.custom_data?.conversion?.display_price_e2 +
                                _tV) *
                                p.cantidad;
                        cant = cant + p.cantidad;
                    });
                    // console.log(parseFloat(formatMonto(acum, 2, "", ".")));
                    setMontoTienda(parseFloat(formatMonto(acum, 2, "", ".")))
                    //console.log('acum',acum);
                    //setSubTotal(acum);
                    setCantidadProds(cant);
                }
                if(setPreloader){
                    setPreloader(false);
                }
            }
        })();
    }, [carStore.productos, carStore]);

    useEffect(() => {
        let cant = 0;
        carStore.productos.map((p) => {
            cant = cant + p.cantidad;
        });
        setCantidadProds(cant);
    }, [carStore.productos]);

    // useEffect(() => {
    //     if (Productos !== null) {
    //         let acum = 0;
    //         let cant = 0;
    //         carStore.productos.map((p) => {
    //             console.log("variantesssssssssssssssssssssssssssssss", p.variantes);
    //             let producto = Productos.find((n) => n.id === p.id);
    //             let _tV = getTotalVariantes(producto, p);
    //             console.log(_tV)
    //             acum +=
    //                 (parseFloat(
    //                     formatMonto(
    //                         producto.custom_data.conversion.display_price_e2,
    //                         2,
    //                         ",",
    //                         "."
    //                     )
    //                 ) +
    //                     _tV) *
    //                 p.cantidad;
    //             cant = cant + p.cantidad;
    //         });
    //         setSubTotal(acum);
    //         setCantidadProds(cant);
    //     }
    // }, [Productos, carStore.productos]);
    return (
        <section id="detailCarTop">
            <section>
                <div className="PerfectScrollbar">
                    {carStore.productos.map((p) => {
                        // let totalV = getTotalVariantes(prodTemp, p);
                        let pOrigin = Productos.find((n) => n.id === p.id);
                        if (!pOrigin) {
                            return null;
                        }
                        let totalCV = getTotalConVariantes(pOrigin, p);
                        // console.log(totalCV, "totalcv----------------");
                        return (
                            <div className="wrapItem" key={p.id}>
                                <div className="item d-flex">
                                    <figure>
                                        <img
                                            src={p && p.picture_urls[0]}
                                            alt="img prod"
                                        />
                                    </figure>
                                    <div className="det">
                                        <div className="nombre mb-3">
                                            {p && p.name}
                                        </div>
                                        <div className="buttons d-flex">
                                            <ButtonMinus
                                                canDisable={true}
                                                car={carStore.productos}
                                                id={p.id}
                                            />
                                            <span>{p.cantidad}</span>
                                            <ButtonPlus
                                                car={carStore.productos}
                                                id={p.id}
                                            />
                                        </div>
                                    </div>
                                    <div className="price d-flex">
                                        <div className="monto mb-1">
                                            {
                                                p.custom_data.conversion
                                                    .currency.symbol
                                            }
                                            {/* {currencyFormatDE(
                                                totalCV * p.cantidad,
                                                global.thousandsSeparator,
                                                global.decimalSeparator,
                                                global.MostrarDecimales
                                            )} */}
                                            {formatMonto(
                                                totalCV * p.cantidad,
                                                global.MostrarDecimales ? 2 : 0,
                                                ",",
                                                "."
                                            )}
                                        </div>
                                        <ButtonTrash id={p.id} />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </section>
            {/* {Productos !== null && (
                <footer>
                    <div className="info">
                        {Descuento > 0 && (
                            <p className="d-flex justify-content-between">
                                <span>Descuentos</span>{" "}
                                <span>${Descuento.toFixed(2)}</span>
                            </p>
                        )}
                        <p className="d-flex justify-content-between">
                            <b>Subtotal</b>{" "}
                            <b>
                                {
                                    Productos[0].custom_data.conversion.currency
                                        .symbol
                                }
                                {(SubTotal - Descuento).toFixed(2)}
                            </b>
                        </p>
                    </div>
                    <div className="actions">
                        <a
                            href="#"
                            onClick={() => {
                                navigate.push("/tienda/realizarPago");
                                dispatch(setShowCar(false));
                            }}
                        >
                            <button>
                                {" "}
                                <span> Continuar compra</span>
                            </button>
                        </a>
                        <span onClick={() => dispatch(clearCar())}>
                            Vaciar carro
                        </span>
                    </div>
                </footer>
            )} */}
        </section>
    );
};

export default DetailCar;

const getTotalConVariantes = (productoOrigin, pCar) => {
    // console.log(pCar);
    let _tV = productoOrigin.custom_data.conversion.display_price_e2;
    Object.keys(pCar.variantes).map((v) => {
        let op = pCar.branch_good_properties
            .find((n) => n.property_id === parseInt(v.replace("op_", "")))
            ?.property_options.find((n) => n.id === pCar.variantes[v]);
        // console.log("getTotalConVariantes", v);
        if (op) {
            _tV += op.price_e2;
        }
    });
    // console.log(_tV);
    return _tV;
};
const getTotalVariantes = (productoOrigin, pCar) => {
    let _tV = 0;
    Object.keys(pCar.variantes).map((v) => {
        let op = productoOrigin.branch_good_properties
            .find((n) => n.property_id === parseInt(v.replace("op_", "")))
            ?.property_options.find((n) => n.id === pCar.variantes[v]);
            console.log(op)
        if (op) {
            _tV += op.price_e2;
        }
    });
    return _tV;
};
