import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import ReactPixel from "react-facebook-pixel";
import PropTypes from "prop-types";
import ListItems from "./ListItems";
import SeatsMap from "./SeatsMap";
import FreeAssignment from "./FreeAssignment";
import Purchase from "./Purchase";
import moment from "moment";
import Services from "../../services/Services";
import Footer from "./../../components/Footer";
import GoTop from "../../components/GoTop";
import Timer from "../../components/Timer";
import Skeleton from "react-loading-skeleton";
import Tienda from "./tienda";
import {
  readCookie,
  deleteCookie,
  currencyFormatDE,
  currencyFormatValue,
  urlFunction,
} from "../../utils/globals";
import { ShoppingCart, NewBack } from "../../utils/iconsSvg";
import ModalDialog from "../../components/ModalDialog";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useHistory } from "react-router-dom";
import Header from "./../../components/Header";


class Tickets extends Component {
  state = {
    showID: null,
    purchase: false,
    ShapeId: null,
    selectedTickets: false,
    imgEvent: "",
    toggleWrap: false,
    khipuLoading: false,
    isLoadingItems: 0,
    error: false,
    allowed: false,
    showSkeleton: true,
    showTienda: false,
    maxBuy: false,
    reserving: false,
  };

  closeSkeleton = (val) => {
    this.setState({
      showSkeleton: false,
    });
  };

  async UNSAFE_componentWillMount() {
    const { reservationSeats, global } = this.props;
    let host = window.location.href.split("host=")[1];
    reservationSeats.forEach(async (rs) => await this.cancelReservation(rs));
    const transaccion = window.location.pathname.split("/")[4];
    const canal = window.location.pathname.split("/")[5];
    if (transaccion && canal == 2) {
      this.setState({ ...this.state, khipuLoading: true });
      const paymentId = readCookie("idKhipu");
      const interval = setInterval(async () => {
        const response = await Services.pay.verificarPagoKhipu(paymentId);
        if (response.status === 200) {
          if (response.data.Result && response.data.TransaccionId > 0) {
            clearInterval(interval);
            deleteCookie("idKhipu");
            this.props.history.push({
              pathname: `/compraexitosa/${response.data.TransaccionId}/2`,
              state: { confirmacion: response.data.Confirmacion },
            });
          } else {
            console.log(response.data);
          }
        }
      }, 3000);
    } else if (transaccion && canal == 1) {
      const paramError = window.location.pathname.split("/")[6];
      if (
        (paramError === null ||
          paramError === undefined ||
          parseInt(paramError, 10) === 0) &&
        parseInt(transaccion, 10) > 0
      ) {
        this.props.history.push(`/compraexitosa/${transaccion}/`);
      } else {
        this.setState({ error: true });
      }
    } else {
      const { showID } = this.props.match.params;
      this.setState({ showID });
      if (showID) {
        Services.shows
          .getShowById(showID, this.props.global.Idioma)
          .then((response) => {
            if (response.status === 200) {
              if (
                (response.data.Iso === null ||
                  response.data.Iso === undefined) &&
                !response.data.RequiereCola
              ) {
                if (!response.data.RequiereCola) {
                  this.props.history.push(`/event/${response.data.EventoId}`);
                }
              } else {
                this.setState({
                  allowed: response.data.RequiereCola ? false : true,
                  imgEvent: `https://ticketmundoblob.blob.core.windows.net/imagenestmusaprueba/Evento_${response.data.EventoId}_517_262.jpeg`,
                });
                this.props.setFunction(response.data);
                // this.setState({
                //   allowed: true,
                // });
                if (response.data.RequiereCola) {
                  if (host === undefined) {
                    window.location.href = response.data.UrlLanding;
                  }

                  Services.waiting.check(host).then((rspApiSIM) => {
                    if (rspApiSIM.status === 200) {
                      if (rspApiSIM.data.is_allowed) {
                        this.setState({
                          allowed: true,
                        });
                      } else {
                        window.location.href = response.data.UrlLanding;
                      }
                    } else {
                      this.setState({
                        allowed: true,
                      });
                    }
                  });
                } else {
                  this.setState({
                    allowed: true,
                  });
                }
              }
            } else {
              console.log(response.status);
            }
          })
          .catch((error) => {
            Swal.fire({
              customClass: {
                title: "titleCode",
              },
              title: `Lo sentimos, esta función no está disponible`,
            }).then((result) => {
              window.history.back();
            });
          });
      }
    }
  }

  changeFunction = (id) => {
    Services.shows
      .getShowById(id, this.props.global.Idioma)
      .then((response) => {
        if (response.status === 200) {
          if (
            (response.data.Iso === null || response.data.Iso === undefined) &&
            !response.data.RequiereCola
          ) {
            if (!response.data.RequiereCola) {
              this.props.history.push(`/event/${response.data.EventoId}`);
            }
          } else {
            this.setState({
              allowed: response.data.RequiereCola ? false : true,
              imgEvent: `https://ticketmundoblob.blob.core.windows.net/imagenestmusaprueba/Evento_${response.data.EventoId}_517_262.jpeg`,
            });
            this.props.setFunction(response.data);
            this.setState({
              allowed: true,
            });
          }
        } else {
          console.log("aaa->>>3", response.status);
        }
      })
      .catch((error) => {
        console.log("aaa->>>4", error);
      });
  };

  componentDidMount() {
    this.forceExpiredReservations()
  }

  componentDidUpdate() {
    if (this.props.selectedFunction && this.props.selectedFunction.FuncionPixelFacebook && this.props.selectedFunction.FuncionPixelFacebook.trim()!==""){
      console.log(
        "Facebook pixel",
        this.props.selectedFunction.FuncionPixelFacebook,
        "------------------------------------------"
      );
      
      ReactPixel.init(this.props.selectedFunction.FuncionPixelFacebook, {
        autoConfig: true,
        debug: false,
      });
      ReactPixel.pageView();
      ReactPixel.track("ViewContent");
    }
  }

  setLoader = (val) => {
    const { reservationSeats } = this.props;

    this.setState({
      ...this.state,
      isLoadingItems:
        this.state.isLoadingItems + val < 0
          ? 0
          : this.state.isLoadingItems + val,
    });
    this.setState({ selectedTickets: false });
  };
  setShowTienda = (val) => {
    this.setState({ showTienda: val });
  };

  handleToggleWrap = () => {
    this.setState({ toggleWrap: !this.state.toggleWrap });
  };

  handleToggleWrapOut = () => {
    if (this.state.toggleWrap === true) {
      this.setState({ toggleWrap: !this.state.toggleWrap });
    }
  };

  handleSelectTicket = () => {
    this.setState({ ...this.state, selectedTickets: true });
  };
  cancelReservation = async (item) => {
    this.setLoader(1);
    try {
      if (item) {
        if (
          document.getElementsByTagName("iframe")[0] &&
          document.getElementsByTagName("iframe")[0].contentWindow
        ) {
          document
            .getElementsByTagName("iframe")[0]
            .contentWindow.postMessage(item.ShapeId.toString(), "*"); //desmarcar puesto en el mapa SIMPP
          this.setState({ ShapeId: item.ShapeId });
        }
        this.props.deleteReservation(item);
        if (item.bloqueado !== true) {
          const cancelReservation =
            await Services.reservations.cancelReservation(item.Id);
        }
      }
      this.setLoader(-1);
    } catch (error) {
      console.error(error,item);
      let reservation = this.props.setReservation(item);
      document
        .getElementsByTagName("iframe")[0]
        .contentWindow.postMessage(
          { PuestosSeleccionados: [item.ShapeId] },
          "*"
        ); //Marcar puesto en el mapa SIMPP*/
      this.setLoader(-1);
    }
    
  };

  forceExpiredReservations = async (item) => {
    localStorage.removeItem("reservationSeats");
    this.setLoader(1);
    console.log('forceExpiredReservations',item)
    try {
      if (item) {
        if (document.getElementsByTagName("iframe")[0]) {
          document
            .getElementsByTagName("iframe")[0]
            .contentWindow.postMessage({
              ReservaExpirada: true
            }, "*"); //desmarcar puesto en el mapa SIMPP
        }
      }
      this.setLoader(-1);
    } catch (error) {
      console.error(error);
      this.setLoader(-1);
    }
  };

  handlePay = async () => {
    this.setState({ reserving: true });
    ReactPixel.pageView();
    ReactPixel.fbq("track", "AddToCart");
    const groupByTypeId = this.props.reservationSeats
      .filter((p) => p.bloqueado)
      .reduce((group, reservation) => {
        const { TipoEntradaId } = reservation;
        group[TipoEntradaId] = group[TipoEntradaId] ?? [];
        group[TipoEntradaId].push(reservation);
        return group;
      }, {});
    const body = {
      CanalId: this.props.global.Canalid,
      MontoConversion: 0,
      Reservaciones: [],
    };

    const Reservaciones = Object.keys(groupByTypeId).map((key) => {
      return {
        FuncionId: groupByTypeId[key][0].funcionId,
        TipoEntradaId: Number(key),
        Puestos: groupByTypeId[key].map((p) => p.ShapeId),
      };
    });

    body.Reservaciones = Reservaciones;
    const { data: dataRes, status } =
      await Services.reservations.createReservationV2(body);
    if (status === 200) {
      this.props.changeBloquedToReserve(dataRes.Tickets);
      if (this.props.selectedFunction.DDBranchId && !this.state.showTienda) {
        this.setState({ ...this.state, showTienda: true, purchase: false });
      } else {
        this.setState({ ...this.state, purchase: true, showTienda: false });
      }
      this.setState({ reserving: false });
    }
  };

  BtnBack = async () => {
    const { selectedFunction,global } = this.props;
    /*console.log('entre purchase',this.state.purchase)
    console.log('entre showTienda',this.state.showTienda)*/
    if((this.state.purchase === false && this.state.showTienda === false)){
      window.location.href = `${selectedFunction.UrlLanding && selectedFunction.UrlLanding.trim() !== "" ? selectedFunction.UrlLanding : `${global.logoRedirect}event/${selectedFunction.EventoId}`}`

    }else{
      this.setState({ purchase: false, showTienda: false });
      setTimeout(() => {
        if (document.getElementById("mapa-boletos-wrap") === null) {
          const { reservationSeats } = this.props;
          reservationSeats.forEach(
            async (rs) =>
              await Services.reservations
                .cancelReservation(rs.Id)
                .then( ()=> {
                  this.props.deleteReservation(rs)
                }).catch(error => {
                  console.log('error cancelReservation',rs)
                  this.props.deleteReservation(rs)
                })
          );
        }
      }, 1000);
    }
  };

  render() {
    const {
      selectedFunction,
      global,
      setReservation,
      reservationSeats,
      setResumeTickets,
      resumeTickets,
      t,
    } = this.props;
    const { cancelReservation, handlePay, handleSelectTicket, setLoader } =
      this;
    const {
      showID,
      purchase,
      ShapeId,
      selectedTickets,
      imgEvent,
      khipuLoading,
      isLoadingItems,
      showTienda,
    } = this.state;
    let date = null;
    date = selectedFunction ? moment(selectedFunction.Fecha) : null;

    var MontoTotal = 0;
    var ComisionTotal = 0;
    reservationSeats &&
      reservationSeats.forEach((element) => {
        if (element) {
          if (global.mostrarCargos && selectedFunction.TipoDesglose !== 2) {
            MontoTotal =
              selectedFunction.Iso === "VES"
                ? global.MonedaPorDefecto === "USD"
                  ? MontoTotal +
                    (currencyFormatValue(
                      global.dolarToday,
                      element.Precio,
                      global.MostrarDecimales
                    ) +
                      currencyFormatValue(
                        global.dolarToday,
                        element.Comision,
                        global.MostrarDecimales
                      ))
                  : MontoTotal + (element.Precio + element.Comision)
                : MontoTotal + (element.Precio + element.Comision);
          } else if (selectedFunction.TipoDesglose === 2) {
            MontoTotal =
              selectedFunction.Iso === "VES"
                ? global.MonedaPorDefecto === "USD"
                  ? MontoTotal +
                    currencyFormatValue(
                      global.dolarToday,
                      element.Precio +
                        element.Comision -
                        (element.MontoIVA + element.ComisionIVA),
                      global.MostrarDecimales
                    )
                  : MontoTotal +
                    (element.Precio +
                      element.Comision -
                      (element.MontoIVA + element.ComisionIVA))
                : MontoTotal +
                  (element.Precio +
                    element.Comision -
                    (element.MontoIVA + element.ComisionIVA));
          } else {
            MontoTotal =
              selectedFunction.Iso === "VES"
                ? global.MonedaPorDefecto === "USD"
                  ? MontoTotal +
                    currencyFormatValue(
                      global.dolarToday,
                      element.Precio,
                      global.MostrarDecimales
                    )
                  : MontoTotal + element.Precio
                : MontoTotal + element.Precio;
          }
          ComisionTotal += (MontoTotal * element.Valor) / 100;
        }
      });
    // console.log(ComisionTotal, "-------------------");
    // console.log(selectedFunction);
    if (khipuLoading) {
      return (
        <div className="spinner-main spinner2">
          <div className="loader2 loadingio-spinner-rolling-k94er4xvtw2">
            <div className="ldio-arie1i327iv">
              <div></div>
            </div>
          </div>
          <div>
            <span>{t("validatingPurchase")}</span>
          </div>
        </div>
      );
    }
   
    return (
      <Fragment>
        <GoTop />
        <Header customStyle={`tk_events`} backBtn={
          <span className="responsive_back" onClick={()=> this.BtnBack()}>
            <NewBack />
          </span>
        }/>
        <section id="main" className="main-mapa">
          <div className="clear-fl bg-ppal tm-header">
            <div className="container-custom-tm">
              <div className="banner-evento">
                <figure className="img-evento">
                  {selectedFunction && imgEvent.trim() !== ""? (
                    <img src={imgEvent} alt="" className="logo" /> 
                  ) :<Skeleton height={125} width={225} />}
                </figure>
                <div className="info-evento clear-fl d-flex h-100">
                  <div className="datos color-titulos flex-grow-1">
                    
                    <div>
                      <div className="title sub-title-bold" id="eventBanner">
                        {selectedFunction ? selectedFunction.EventoNombre : ""}
                      </div>
                      <div
                        className="fecha text-14-153 color-texto-contenido"
                      >
                        {selectedFunction &&
                        (selectedFunction.EventoNombre.toLowerCase().indexOf(
                          "abonado"
                        ) >= 0 ||
                          selectedFunction.EventoNombre.toLowerCase().indexOf(
                            "abono"
                          ) >= 0)
                          ? "Todas las fechas"
                          : date
                          ? date.format(global?.Idioma === 'EN'? "dddd, MMMM DD" : "dddd DD MMMM") +
                            " " +
                            moment
                              .utc(date)
                              .format(
                                this.props.global.formatoFecha === "12hr"
                                  ? (global?.Idioma === 'EN'? ", [at] hh:mm a" : "hh:mm a")
                                  : (global?.Idioma === 'EN'? ", [at] HH:mm" : "HH:mm")
                              )
                          : ""}
                        {this.props.global.formatoFecha &&
                        this.props.global.formatoFecha !== "12hr"
                          ? " hrs"
                          : ""}
                      </div>
                    </div>

                    <div className="direccion text-14-153 color-texto-contenido">
                      {selectedFunction ? `${selectedFunction.SalaNombre}` : ""}
                    </div>
                    {/*selectedFunction && (
                      <a
                        href={
                          selectedFunction.UrlLanding &&
                          selectedFunction.UrlLanding.trim() !== ""
                            ? selectedFunction.UrlLanding
                            : `${global.logoRedirect}event/${selectedFunction.EventoId}`
                        }
                        className="back color-ppal"
                      >
                        {t("homeLink")}
                      </a>
                    )*/}
                  </div>
                  {
                     reservationSeats?.length >0 || selectedTickets ? (
                      <Timer
                        global={global}
                        redirect={
                          selectedFunction.RequiereCola
                            ? selectedFunction.UrlLanding
                            : false
                        }
                      />
                    )
                    : 
                    <figure className="logo-ext">
                      {global.logo ? <img src={global.logo} alt="logo-ext" /> : null}
                    </figure>
                  }
                </div>
              </div>
            </div>
          </div>
          {this.state.allowed ?
              (selectedFunction &&
              selectedFunction.MapaSvgId === null &&
              showID ? (
                (!showTienda && !purchase) ?
                <FreeAssignment
                  showID={showID}
                  Simbolo={selectedFunction.MonedaSimbolo}
                  selectedFunction={selectedFunction}
                  Iso={selectedFunction.Iso}
                  handleSelectTicket={handleSelectTicket}
                  setReservation={setReservation}
                  setLoader={setLoader}
                  setResumeTickets={setResumeTickets}
                  pay={handlePay}
                  event={this.props.event}
                  setTotalMount={this.props.setTotalMount}
                  cantMaxEnt={selectedFunction.CantidadMaximaEntradasCanales}
                  global={this.props.global}
                />
                :null
              ) : (
                <Fragment>
                  <section
                    id="wrap-ppal"
                    className="pageMapa"
                    onClick={this.handleToggleWrapOut}
                    style={ (showTienda || purchase) ? {display:`none`} : {} }
                  >
                    <div id="wrap-cont">
                      <div className="container-custom-tm clear-fl">
                        <div id="mapa-boletos-wrap">
                          <div
                            className="mapa"
                            style={{
                              position: "relative",
                            }}
                          >
                            {/* {selectedFunction &&
                              selectedFunction.CantidadMaximaEntradasCanales ===
                                reservationSeats.length && (
                                <span
                                  onClick={() => {
                                    this.setState({ maxBuy: true });
                                  }}
                                  style={{
                                    display: "block",
                                    position: "absolute",
                                    left: "0",
                                    top: "0",
                                    width: "100%",
                                    height: "100%",
                                    zIndex: "99999",
                                    background: "rgba(0,0,0,0)",
                                  }}
                                ></span>
                              )} */}
                            <div className="container-map">
                              {selectedFunction &&
                              showID &&
                              selectedFunction.MapaSvgId >= 1 ? (
                                <>
                                  {/* {selectedFunction.RequiereCola && (
                                                                      <div className="multiplesFunctions">
                                                                          {selectedFunction.listFuncions &&
                                                                              selectedFunction.listFuncions.map(
                                                                                  (
                                                                                      f
                                                                                  ) =>
                                                                                      f.NombreOpcionalRecinto &&
                                                                                      f.NombreOpcionalRecinto.trim() !==
                                                                                          "" ? (
                                                                                          <a
                                                                                              href="#"
                                                                                              className={`link-to-fun ${
                                                                                                  selectedFunction.FuncionId ===
                                                                                                  f.Id
                                                                                                      ? "active"
                                                                                                      : ""
                                                                                              }`}
                                                                                              key={`fid-${f.Id}`}
                                                                                              onClick={(
                                                                                                  e
                                                                                              ) => {
                                                                                                  e.preventDefault();
                                                                                                  if (
                                                                                                      selectedFunction.FuncionId ===
                                                                                                      f.Id
                                                                                                  )
                                                                                                      return;
                                                                                                  this.changeFunction(
                                                                                                      f.Id
                                                                                                  );
                                                                                              }}
                                                                                          >
                                                                                              {
                                                                                                  f.NombreOpcionalRecinto
                                                                                              }
                                                                                          </a>
                                                                                      ) : null
                                                                              )}
                                                                      </div>
                                                                  )} */}
                                  <SeatsMap
                                    selectedFunction={selectedFunction}
                                    handleSelectTicket={handleSelectTicket}
                                    middleWareSeat={() => {
                                      this.setState({
                                        maxBuy: true,
                                      });
                                    }}
                                    noPaintSeat={(item) => {
                                      this.props.deleteReservation(item);
                                    }}
                                    setLoader={setLoader}
                                    setReservation={setReservation}
                                    cancelReservation={cancelReservation}
                                    items={reservationSeats}
                                    showID={showID}
                                    shapeId={ShapeId}
                                    global={this.props.global}
                                    dolarToday={this.props.global.dolarToday}
                                    MonedaPorDefecto={
                                      this.props.global.MonedaPorDefecto
                                    }
                                    language={this.props.global.Idioma.toLowerCase()}
                                    setResumeTickets={this.props.setResumeTickets}
                                    resumeTickets={this.props.resumeTickets}
                                    reservationSeats={this.props.reservationSeats}
                                    setTotalMount={this.props.setTotalMount}
                                  />
                                </>
                              ) : null}
                            </div>
                          </div>
                          {selectedFunction &&
                          showID &&
                          selectedFunction.MapaSvgId >= 1 ? (
                            <ListItems
                              items={reservationSeats}
                              resumeTickets={resumeTickets}
                              pay={handlePay}
                              reserving={this.state.reserving}
                              selectedFunction={selectedFunction}
                              Iso={selectedFunction.Iso}
                              Simbolo={selectedFunction.MonedaSimbolo}
                              cancelReservation={cancelReservation}
                              setResumeTickets={setResumeTickets}
                              toggleWrap={this.state.toggleWrap}
                              isLoadingItems={isLoadingItems}
                              global={global}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </section>
                </Fragment>
              )
              ):null
          }
          {showTienda ? (
            <Tienda
              cancelReservation={cancelReservation}
              deleteReservation={this.props.deleteReservation}
              setResumeTickets={setResumeTickets}
              setTotalMount={this.props.setTotalMount}
              showID={showID}
              back={() => this.BtnBack()}
              goPurchase={handlePay}
            />
          ) : purchase ? (
            <Purchase
              showID={showID}
              cancelReservation={cancelReservation}
              closeSkeleton={this.closeSkeleton}
              showSkeleton={this.state.showSkeleton}
              reservationSeats={reservationSeats}
              isGratis={!this.props.resumeTickets.some((tk) => tk.Precio > 0)}
              selectedFunction={selectedFunction}
              handlePay={handlePay}
              back={() => this.BtnBack()}
            />
          ) : this.state.allowed ? (
              selectedFunction &&
              selectedFunction.MapaSvgId === null &&
              showID ? '':'' ): (
              <div id="wrap-ppal" className="pageMapa">
                <div className="container-custom-tm clear-fl ">
                  <div className="col-xs-12 col-lg-9">
                    <div className="skel-img">
                      <Skeleton height={410} />
                    </div>
                  </div>
                  <div className="col-xs-12 col-lg-3">
                    <Skeleton height={200} />
                  </div>
                </div>
              </div>
          )}
        </section>
        {purchase ||
        (selectedFunction && selectedFunction.MapaSvgId === null) ? (
          !purchase ? (
            <Footer />
          ) : null
        ) : !showTienda ? (
          <div id="footer-m">
            <div
              className="container-custom-tm ajust_flex"
              onClick={this.handleToggleWrapOut}
            >
              {/* <div className="car show-seats">
                <span
                  onClick={() => this.handleToggleWrap()} 
                >
                  <i className="icon-carrito" style={{ color: "#fff" }} ></i>
                  {(reservationSeats.length) && <span>{reservationSeats.length}</span>}
                </span>
                <p className="" style={{ color: 'rgb(255,255,255)' }}>
                  {(MontoTotal || reservationSeats.length > 0) ? <span>$ {MontoTotal.toLocaleString('de-DE')}</span> : <span>No hay entradas</span>}
                </p>:
                  </div>
               */}
              <div className="car color-b">
                {isLoadingItems ? (
                  <div className="spinner-main spinner4">
                    <div className="loadingio-spinner-rolling-k94er4xvtw spinner4-b">
                      <div className="ldio-arie1i327iv spinner4-c">
                        <div></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <span onClick={() => this.handleToggleWrap()} style={{width:'42px',height: '32px',alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                    <ShoppingCart />
                    {/*<img
                                            src="/images/icono.svg"
                                            alt=""
                                        ></img>*/}
                    <span style={{}}>
                      {reservationSeats.length && reservationSeats.length}
                    </span>
                  </span>
                )}

                <p>
                  {MontoTotal || reservationSeats.length > 0 ? (
                    selectedFunction.TipoDesglose !== 2 ? (
                      <span>
                        {selectedFunction.Iso === "VES"
                          ? this.props.global.MonedaPorDefecto
                          : selectedFunction.MonedaSimbolo}
                        {currencyFormatDE(
                          MontoTotal,
                          this.props.global.thousandsSeparator,
                          this.props.global.decimalSeparator,
                          this.props.global.MostrarDecimales
                        )}
                        {/* {global.mostrarCargos && (
                                                    <span> + {t("fee")}</span>
                                                )} */}
                      </span>
                    ) : (
                      <span>
                        {selectedFunction.Iso === "VES"
                          ? this.props.global.MonedaPorDefecto
                          : selectedFunction.MonedaSimbolo}
                        {currencyFormatDE(
                          MontoTotal + ComisionTotal,
                          this.props.global.thousandsSeparator,
                          this.props.global.decimalSeparator,
                          this.props.global.MostrarDecimales
                        )}
                      </span>
                    )
                  ) : (
                    <span>{t("tickets")}</span>
                  )}
                </p>
              </div>

              <button
                className={`btn-skin btn-get-tk-m btn-ppal ${
                  MontoTotal > 0 ? "active" : ""
                }`}
                onClick={() => {
                  if (reservationSeats.length >= 1) {
                    this.setState({ reserving: true });
                    handlePay();
                  }
                }}
                data-href="pago.html"
                disabled={this.state.reserving}
              >
                {this.state.reserving ? 
                <div className="spinner-main spinner3">
                  <div className="loadingio-spinner-rolling-k94er4xvtw spinner3-b">
                    <div className="ldio-arie1i327iv spinner3-c">
                      <div
                        style={{
                          border: `10px`,
                          borderStyle: "solid",
                          borderColor: `#ffffff`,
                          borderTopColor: "transparent",
                        }}
                      ></div>
                    </div>
                  </div>
                </div> 
                : t("continue")}
              </button>
            </div>
          </div>
        ) : null}
        <ModalDialog
          display={this.state.error}
          setDisplay={(error) => {
            console.log('cuando abre el ticket')
            this.setState({ error: error })}
          }
          title={t("paymentError")}
          content={t("paymentErrorMsg")}
          extra={t("tryAgain").toUpperCase()}
          buttonText={t("labelOK").toUpperCase()}
          handleClick={() => (window.location.href = urlFunction(3))}
        />

        {selectedFunction && (
          <div
            className={
              this.state.maxBuy
                ? "modal fade modalAlert in"
                : "modal fade modalAlert"
            }
            style={{
              display: this.state.maxBuy ? "block" : "none",
              zIndex: 99999999,
            }}
            id="modalConfirm"
            role="dialog"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-md"
              role="document"
            >
              <div className="modal-content">
                <div className="conten-expired clear-fl">
                  <div className="top">
                    <i className="icon-alert color-b"></i>
                  </div>
                  <div className="content">
                    <div className="title color-titulos">
                      Ha alcanzado la cantidad máxima de tickets por compra.
                    </div>
                    <p className="color-texto-contenido">
                      Este evento solo permite{" "}
                      {selectedFunction?.CantidadMaximaEntradasCanales} entradas
                      por usuario.
                    </p>
                    <a
                      onClick={async () => {
                        this.setState({
                          maxBuy: false,
                        });
                      }}
                      id="conf-del"
                      className="color-ppal hover-text-ppal"
                    >
                      Aceptar
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

Tickets.propTypes = {
  setFunction: PropTypes.func.isRequired,
};

export default withTranslation()(React.memo(Tickets));
