import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Skeleton from 'react-loading-skeleton';
import Services from "../../services/Services";
import CurrencyInput from "../../components/CurrencyInput";
import GoTop from "../../components/GoTop";
import ReactPixel from "react-facebook-pixel";
import {
    currencyFormatDE,
    currencyFormatDonation,
    readCookie,
    deleteCookie,
    urlFunction,
} from "../../utils/globals";
import PayDonation from "./PayDonation";
import ModalDialog from "../../components/ModalDialog";
import { error } from "jquery";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const Donations = (props) => {
    const {
        global,
        loading,
        t,
        organization,
        setGlobalConfig,
        startGetConfig,
    } = props;
    // console.log(organization);
    const [indexDonation, setIndexDonation] = useState(0);
    const [amountDonation, setAmountDonation] = useState(0);
    const [Montos, setMontos] = useState([]);
    const [LabelMontos, setLabelMontos] = useState([]);
    const [moneda, setMoneda] = useState("");
    const [isoMonto, setIsoMonto] = useState(null);
    const [payDonation, setPayDonation] = useState(false);
    const [otherAmountDonation, setOtherAmountDonation] = useState("");
    const [khipuLoading, setKhipuLoading] = useState(false);
    const [error, setError] = useState(false);
    const { orgId } = props.match.params;
    let labelPayButton = React.useRef(t("donate"));

    useEffect(() => {
        const { orgId } = props.match.params;
        const transaccion = window.location.pathname.split("/")[3];
        const canal = window.location.pathname.split("/")[4];

        if (transaccion) {
            switch (parseInt(canal, 10)) {
                case 1: //transbank
                    const paramError = window.location.pathname.split("/")[5];
                    if (
                        (paramError === null ||
                            paramError === undefined ||
                            parseInt(paramError, 10) === 0) &&
                        parseInt(transaccion, 10) > 0
                    )
                        props.history.push({
                            pathname: `/donacionexitosa/${transaccion}/`,
                            state: { org: organization },
                        });
                    else {
                        setError(true);
                    }
                    break;
                case 2: //khipu
                    setKhipuLoading(true);
                    const paymentId = readCookie("idKhipu");
                    const interval = setInterval(async () => {
                        const response = await Services.pay.verificarPagoKhipu(
                            paymentId
                        );
                        if (response.status === 200) {
                            if (
                                response.data.Result &&
                                response.data.TransaccionId > 0
                            ) {
                                clearInterval(interval);
                                deleteCookie("idKhipu");
                                props.history.push({
                                    pathname: `/donacionexitosa/${response.data.TransaccionId}`,
                                    state: {
                                        confirmacion:
                                            response.data.Confirmacion,
                                        org: organization,
                                    },
                                });
                            } else {
                                console.log(response.data);
                            }
                        }
                    }, 3000);
                    break;
                default:
                    break;
            }
        } else {
            if (orgId) {
                (async () => {
                    const org = await Services.donaciones.getOrganizacionById(
                        orgId,
                        global.Idioma
                    );
                    props.setOrganization(org.data);
                    //console.log(org.data)
                    setIsoMonto({
                        [org.data.Iso]: {
                            montos: org.data.Montos,
                            simbolo: org.data.MonedaSimbolo,
                            paisId: org.data.PaisId,
                            comercioId: org.data.ComercioId,
                        },
                        [org.data.IsoOpcion2]: {
                            montos: org.data.MontoOpcion2,
                            simbolo: org.data.MonedaSimboloOpcion2,
                            paisId: org.data.PaisIdOpcion2,
                            comercioId: org.data.ComercioIdOpcion2,
                        },
                        [org.data.IsoOpcion3]: {
                            montos: org.data.MontoOpcion3,
                            simbolo: org.data.MonedaSimboloOpcion3,
                            paisId: org.data.PaisIdOpcion3,
                            comercioId: org.data.ComercioIdOpcion3,
                        },
                    });
                    setMoneda(org.data.Iso);
                    //setMontos(org.data.Montos);
                    setAmountDonation(org.data.Montos[0]);
                })();
            }
        }
    }, []);

    useEffect(() => {
        const { orgId } = props.match.params;
        if (orgId) {
            startGetConfig();
            (async () => {
                const org = await Services.donaciones.getOrganizacionById(
                    orgId,
                    global.Idioma
                );
                //console.log(org.data);
                props.setOrganization(org.data);
                setGlobalConfig({ loading: false });
                setMoneda(org.data.Iso);
                setMontos(org.data.Montos);
                setAmountDonation(org.data.Montos[0]);
            })();
        }
    }, [props.global.Idioma]);
    useEffect(() => {
        if (
            global.Idioma.toLowerCase() === "es" &&
            organization &&
            organization.labelPayButton
        ) {
            labelPayButton.current = organization.labelPayButton;
        } else if (
            global.Idioma.toLowerCase() === "en" &&
            organization &&
            organization.labelPayButton_en
        ) {
            labelPayButton.current = organization.labelPayButton_en;
        }

        if (
            organization &&
            organization.labelMontos &&
            global.Idioma.toLowerCase() === "es"
        ) {
            setLabelMontos(organization.labelMontos.split(";"));
        } else if (
            organization &&
            organization.labelMontos &&
            global.Idioma.toLowerCase() === "en"
        ) {
            setLabelMontos(organization.labelMontos_en.split(";"));
        }
    }, [organization]);

    useEffect(() => {
        if (organization && isoMonto) {
            setMontos(isoMonto[moneda].montos);
            setOtherAmountDonation("");
            setAmountDonation(isoMonto ? isoMonto[moneda].montos[0] : "");
            setIndexDonation(0);
        }
    }, [moneda]);

    const toCurrency = (amount) => {
        return currencyFormatDE(
            amount,
            global.thousandsSeparator,
            global.decimalSeparator,
            global.MostrarDecimales
        );
    };

    return (
        <>
            <GoTop />
            <Header translate={!payDonation} />
            {payDonation ? (
                <PayDonation
                    {...props}
                    totalMount={amountDonation}
                    moneda={moneda}
                    paisId={isoMonto[moneda].paisId}
                    comercioId={isoMonto[moneda].comercioId}
                    setPayDonation={setPayDonation}
                />
            ) : khipuLoading ? (
                <div className="spinner-main spinner2">
                    <div className="loader2 loadingio-spinner-rolling-k94er4xvtw2">
                        <div className="ldio-arie1i327iv">
                            <div></div>
                        </div>
                    </div>
                    <div>
                        <span>{t("validatingPurchase")}</span>
                    </div>
                </div>
            ) : (
                (global.loading || loading) ? 
                <>
                    <span className="bg-top">
                        
                    </span>
                    <section id="main" className="mainE">
                        <div className="container-custom-tm">
                            <div className="wrap-evento clear-fl" style={{flexDirection: `column`}}>
                                <div className="banner-donacion clear-fl">
                                    <figure style={{ marginTop: `-5px` }}>
                                        <Skeleton height={403} />
                                    </figure>
                                </div>
                                <div className="titulo-donation color-titulos">
                                </div>
                                <div className="description-banner">
                                </div>
                            </div>
                        </div>
                    </section>
                </>
                :
                <>
                    <span className="bg-top">
                        {global.BannerImagen && global.BannerUrl.trim() !== "" &&  (
                            <img
                                className="bg-top"
                                src={global.BannerUrl}
                                alt=""
                            ></img>
                        )}
                    </span>
                    <section id="main" className="mainE">
                        <div className="container-custom-tm">
                            <div className="wrap-evento clear-fl" style={{flexDirection: `column`}}>
                                <div className="banner-donacion clear-fl">
                                    <figure>
                                        {
                                            organization &&
                                            organization.BannerDonacionGrande &&
                                            organization.BannerDonacionGrande !="" ?
                                            <img
                                                src={
                                                    organization &&
                                                    organization.BannerDonacionGrande &&
                                                    organization.BannerDonacionGrande !=
                                                        ""
                                                        ? organization.BannerDonacionGrande
                                                        : global.Idioma.toLowerCase() ===
                                                                "es" &&
                                                            global.BannerDonacionGrande !=
                                                                ""
                                                        ? global.BannerDonacionGrande
                                                        : global.BannerDonacionGrande_en
                                                }
                                                alt="Imagen Evento"
                                            />
                                        :
                                            <Skeleton height={403} />
                                        }
                                        
                                    </figure>
                                </div>
                                {organization ? (
                                    <>
                                        <div className="titulo-donation color-titulos">
                                            {organization.Nombre}
                                        </div>
                                        <div className="description-banner">
                                            <div className="descripcion">
                                                <div className="desc color-texto-contenido">
                                                    <div
                                                        id="titleDescription"
                                                        className="title sub-title-bold color-titulos"
                                                    >
                                                        {
                                                            organization.TituloDescripcionSkin1
                                                        }
                                                    </div>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                organization.DescripcionSkin1,
                                                        }}
                                                    ></div>
                                                </div>
                                                <div className="monedasCheck color-texto-contenido">
                                                    {organization.Iso && (
                                                        <a
                                                            onClick={() =>
                                                                setMoneda(
                                                                    organization.Iso.toUpperCase()
                                                                )
                                                            }
                                                            className={
                                                                moneda ===
                                                                    organization.Iso.toUpperCase() &&
                                                                "active"
                                                            }
                                                        >
                                                            <img
                                                                src={`/images/${
                                                                    BANDERAS[
                                                                        organization.Iso.toUpperCase()
                                                                    ]
                                                                }`}
                                                            />
                                                            <span>
                                                                {organization.Iso.toUpperCase()}
                                                            </span>
                                                        </a>
                                                    )}
                                                    {organization.IsoOpcion2 && (
                                                        <a
                                                            onClick={() =>
                                                                setMoneda(
                                                                    organization.IsoOpcion2.toUpperCase()
                                                                )
                                                            }
                                                            className={
                                                                moneda ===
                                                                    organization.IsoOpcion2.toUpperCase() &&
                                                                "active"
                                                            }
                                                        >
                                                            <img
                                                                src={`/images/${
                                                                    BANDERAS[
                                                                        organization.IsoOpcion2.toUpperCase()
                                                                    ]
                                                                }`}
                                                            />
                                                            <span>
                                                                {organization.IsoOpcion2.toUpperCase()}
                                                            </span>
                                                        </a>
                                                    )}
                                                    {organization.IsoOpcion3 && (
                                                        <a
                                                            onClick={() =>
                                                                setMoneda(
                                                                    organization.IsoOpcion3.toUpperCase()
                                                                )
                                                            }
                                                            className={
                                                                moneda ===
                                                                    organization.IsoOpcion3.toUpperCase() &&
                                                                "active"
                                                            }
                                                        >
                                                            <img
                                                                src={`/images/${
                                                                    BANDERAS[
                                                                        organization.IsoOpcion3.toUpperCase()
                                                                    ]
                                                                }`}
                                                            />
                                                            <span>
                                                                {organization.IsoOpcion3.toUpperCase()}
                                                            </span>
                                                        </a>
                                                    )}
                                                </div>
                                                {isoMonto &&
                                                    isoMonto[moneda] &&
                                                    isoMonto[moneda].montos
                                                        .length > 0 && (
                                                        <>
                                                            <div className="grid-amount-donations">
                                                                {isoMonto[
                                                                    moneda
                                                                ].montos.map(
                                                                    (
                                                                        d,
                                                                        index
                                                                    ) => {
                                                                        console.log(
                                                                            LabelMontos[
                                                                                index
                                                                            ]
                                                                        );
                                                                        return (
                                                                            <button
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className={`btn-skin btn-donation btn-ppal ${
                                                                                    index ===
                                                                                    indexDonation
                                                                                        ? "active"
                                                                                        : "tm-border"
                                                                                }`}
                                                                                style={{
                                                                                    maxWidth:
                                                                                        LabelMontos.length >
                                                                                        0
                                                                                            ? "100%"
                                                                                            : "130px",
                                                                                }}
                                                                                 onClick={() => {
                                                                                    setOtherAmountDonation(
                                                                                        ""
                                                                                    );
                                                                                    setIndexDonation(
                                                                                        index
                                                                                    );
                                                                                    setAmountDonation(
                                                                                        d
                                                                                    );
                                                                                    if (
                                                                                        document.getElementById(
                                                                                            "otherAmountDonation"
                                                                                        )
                                                                                    ) {
                                                                                        document.getElementById(
                                                                                            "otherAmountDonation"
                                                                                        ).value =
                                                                                            "";
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {
                                                                                    isoMonto[
                                                                                        moneda
                                                                                    ]
                                                                                        .simbolo
                                                                                }
                                                                                {toCurrency(
                                                                                    d
                                                                                )}{" "}
                                                                                {LabelMontos[
                                                                                    index
                                                                                ]
                                                                                    ? LabelMontos[
                                                                                          index
                                                                                      ]
                                                                                    : ""}
                                                                            </button>
                                                                        );
                                                                    }
                                                                )}

                                                                {(!organization.hasOwnProperty(
                                                                    "allowOtherAmount"
                                                                ) ||
                                                                    organization.allowOtherAmount) && (
                                                                    <div className="container-other-donation">
                                                                        <span>
                                                                            {t(
                                                                                "labelOthers"
                                                                            )}
                                                                        </span>
                                                                        <div>
                                                                            <span>
                                                                                {
                                                                                    isoMonto[
                                                                                        moneda
                                                                                    ]
                                                                                        .simbolo
                                                                                }
                                                                            </span>
                                                                            <CurrencyInput
                                                                                placeholder={`${t(
                                                                                    "minimum"
                                                                                )} ${toCurrency(
                                                                                    isoMonto
                                                                                        ? isoMonto[
                                                                                              moneda
                                                                                          ]
                                                                                              .montos[0]
                                                                                        : 0
                                                                                )}`}
                                                                                id="otherAmountDonation"
                                                                                name="otherAmountDonation"
                                                                                type="text"
                                                                                value={
                                                                                    otherAmountDonation
                                                                                }
                                                                                maskOptions={{
                                                                                    thousandsSeparatorSymbol:
                                                                                        global.thousandsSeparator,
                                                                                    allowDecimal:
                                                                                        global.MostrarDecimales,
                                                                                    decimalSymbol:
                                                                                        global.decimalSeparator,
                                                                                    integerLimit: 8,
                                                                                }}
                                                                                onChange={async (
                                                                                    e
                                                                                ) => {
                                                                                    e.persist();
                                                                                    let valido = await currencyFormatDonation(
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                        global.thousandsSeparator,
                                                                                        global.decimalSeparator,
                                                                                        global.MostrarDecimales
                                                                                    );
                                                                                    if (
                                                                                        e
                                                                                            .target
                                                                                            .value &&
                                                                                        valido >=
                                                                                            isoMonto[
                                                                                                moneda
                                                                                            ]
                                                                                                .montos[0]
                                                                                    ) {
                                                                                        setOtherAmountDonation(
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                        setIndexDonation(
                                                                                            -1
                                                                                        );
                                                                                    } else {
                                                                                        //setOtherAmountDonation("");
                                                                                        setOtherAmountDonation(
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                        setAmountDonation(
                                                                                            isoMonto
                                                                                                ? isoMonto[
                                                                                                      moneda
                                                                                                  ]
                                                                                                      .montos[0]
                                                                                                : ""
                                                                                        );
                                                                                        setIndexDonation(
                                                                                            0
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div>
                                                                <button
                                                                    className="mb-30 mt-30 btn-skin btn-donation-ppal btn-ppal active"
                                                                    onClick={() => {
                                                                        let aux2 = currencyFormatDonation(
                                                                            otherAmountDonation,
                                                                            global.thousandsSeparator,
                                                                            global.decimalSeparator,
                                                                            global.MostrarDecimales
                                                                        );

                                                                        if (
                                                                            !otherAmountDonation ||
                                                                            (aux2 &&
                                                                                aux2 >=
                                                                                    (isoMonto
                                                                                        ? isoMonto[
                                                                                              moneda
                                                                                          ]
                                                                                              .montos[0]
                                                                                        : 0))
                                                                        ) {
                                                                            if (
                                                                                indexDonation <
                                                                                0
                                                                            ) {
                                                                                setAmountDonation(
                                                                                    currencyFormatDonation(
                                                                                        otherAmountDonation,
                                                                                        global.thousandsSeparator,
                                                                                        global.decimalSeparator,
                                                                                        global.MostrarDecimales
                                                                                    )
                                                                                );
                                                                            }
                                                                            setPayDonation(
                                                                                true
                                                                            );
                                                                            //setOtherAmountDonation('')
                                                                        } else {
                                                                            //alert(t("errorMinimum"));
                                                                            Swal.fire(
                                                                                {
                                                                                    customClass: {
                                                                                        title:
                                                                                            "titleCode",
                                                                                    },
                                                                                    title: t(
                                                                                        "errorMinimum"
                                                                                    ),
                                                                                }
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    {`${
                                                                        labelPayButton.current
                                                                    } ${
                                                                        isoMonto[
                                                                            moneda
                                                                        ]
                                                                            .simbolo
                                                                    }${toCurrency(
                                                                        otherAmountDonation
                                                                            ? currencyFormatDonation(
                                                                                  otherAmountDonation,
                                                                                  global.thousandsSeparator,
                                                                                  global.decimalSeparator,
                                                                                  global.MostrarDecimales
                                                                              )
                                                                            : amountDonation
                                                                    )}`}
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                            </div>
                                            <div className="descripcion">
                                                <div className="desc color-texto-contenido">
                                                    <div
                                                        id="titleDescription"
                                                        className="title sub-title-bold color-titulos"
                                                    >
                                                        {
                                                            organization.TituloDescripcionSkin2
                                                        }
                                                    </div>

                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                organization.DescripcionSkin2,
                                                        }}
                                                    ></div>
                                                </div>
                                                <div className="desc color-texto-contenido">
                                                    <div
                                                        id="titleDescription"
                                                        className="title sub-title-bold color-titulos"
                                                    >
                                                        {
                                                            organization.TituloDescripcionSkin3
                                                        }
                                                    </div>

                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                organization.DescripcionSkin3,
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </section>
                    <ModalDialog
                        display={error}
                        setDisplay={(error) => setError(error)}
                        title={t("paymentError")}
                        content={t("paymentErrorMsg")}
                        extra={t("tryAgain").toUpperCase()}
                        buttonText={t("labelOK").toUpperCase()}
                        handleClick={() =>
                            (window.location.href = urlFunction(2))
                        }
                    />
                    <Footer />
                </>
            )}
        </>
    );
};

export default withTranslation()(Donations);

const BANDERAS = {
    CLP: "chile.svg",
    USD: "usa.svg",
    EUR: "eu.svg",
    VES: "ve.svg",
};
